<template>
    <transition name="fade" mode="out-in" duration="250">
        <component v-if="processDefinition !== null && taskForm !== null"
                   :is="taskForm"
                   @submit="submit"
                   @cancel="cancel"
                   :processDefinition="processDefinition"
                   :taskDefinition="task"
                   :variables="variables"></component>
        <GenericTask v-else-if="processDefinition !== null"
                     :variables="taskInstance.task.variables"
                     :task-fields="taskInstance.task.taskDefinition.formProperties"
                     :process-fields="taskInstance.task.formProperties"
                     @submit="submit"
                     @cancel="cancel"></GenericTask>
        <clip-loader v-else class="m-auto" :color="loadingColor"></clip-loader>
    </transition>
</template>

<script>
import _ from 'lodash';
import styles from '@/scss/main.scss';
import { ClipLoader } from 'vue-spinner/dist/vue-spinner.min.js';
import GenericTask from '@/components/dashboard/widgets/workflow/GenericTask';

/**
* @description Dashboard widget that displays the specific details of a task
* @description Dashboard widget that displays the specific details of a task
*
**/
export default {
    name: 'Task',
    props: ['taskInstance'],
    data () {
        return {
            loadingColor: styles.baseColor
        };
    },
    components: {
        'clip-loader': ClipLoader,
        GenericTask
    },
    computed: {
        process () {
            return this.taskInstance.process;
        },
        processDefinition () {
            if (this.process.processDefinition === null) {
                this.$emit('loadProcess', this.process);
            }
            return this.process.processDefinition;
        },
        formProperties () {
            return this.processDefinition ? this.processDefinition.formProperties : [];
        },
        task () {
            return this.taskInstance.task;
        },
        taskDetails () {
            return this.formProperties.reduce((acc, property) => {
                return acc.concat({ _id: property._id, name: property.name, value: this.task.variables[property._id] });
            }, []);
        },
        variables () {
            return _.get(this, 'task.variables');
        },
        taskForm () {
            const formGenerationTemplate = this.task.taskDefinition.formGenerationTemplate,
                    initializeForm = formGenerationTemplate ? Function(`return ${formGenerationTemplate}`) : null // eslint-disable-line

            if (!_.isNull(initializeForm)) {
                return initializeForm();
            } else {
                return null;
            }
        }
    },
    methods: {
        submit (formData) {
            this.$emit('completeTask', { id: this.task._id, formData });
        },
        cancel () {
            this.$emit('cancel', this.task._id);
        }
    }
};
</script>
<style lang="scss">
    .form-label-password.form-label-group {
        display: flex;

        .form-control {
            flex-grow: 1;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }

        .input-group-append {
            flex-grow: 1;

            button {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                background-color: $input-bg;
                border-color: $input-border-color;
                color:$input-btn-color;
            }
            button:hover {
              color: $input-btn-active-color;
            }

        }
    }

    .form-label-group {
        position: relative;
        margin-bottom: 1rem;
    }

    //-->
    // Bootstrap Floating Labels
    // https://getbootstrap.com/docs/4.0/examples/floating-labels/
    //-->
    .form-label-group > input,
    .form-label-group > label,
    .form-label-group > .input-group > input,
    .form-label-group > .input-group > label {
        padding: $input-btn-padding-y;
        text-align: left;
    }

    .form-label-group > label,
    .form-label-group > .input-group > label {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        margin-bottom: 0; /* Override default `<label>` margin */
        line-height: 1.5;
        color: $label-color;
        border: 1px solid transparent;
        border-radius: .25rem;
        transition: all .1s ease-in-out;
    }

    .form-label-group input::-webkit-input-placeholder {
        color: transparent;
    }

    .form-label-group input:-ms-input-placeholder {
        color: transparent;
    }

    .form-label-group input:-ms-input-placeholder {
        color: transparent;
    }

    .form-label-group input::-moz-placeholder {
        color: transparent;
    }

    .form-label-group input::placeholder {
        color: transparent;
    }

    .form-label-group .polyfillPlaceholder {
        padding-top: $input-btn-padding-y + $input-btn-padding-y * (2 / 3);
        padding-bottom: $input-btn-padding-y / 3;
        color: $input-color;
    }

    .form-label-group .polyfillPlaceholder ~ label {
        padding-top: $input-btn-padding-y / 3;
        padding-bottom: $input-btn-padding-y / 3;
        font-size: 12px;
        color: $label-color;
    }
</style>
