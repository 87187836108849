<template>
    <DxDataGrid
        :allow-column-reordering="true"
        :data-source="dataSource"
        :show-borders="true"
        height="inherit"
        :show-column-lines="false"
        :column-auto-width="true"
        :column-min-width="50"
        :allow-column-resizing="true"
        @editor-preparing="onEditorPreparing"
        @row-prepared="onRowPrepared"
        @saving="onSaving"
        :no-data-text="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.noDataText')"
    >
        <DxEditing
            :allow-updating="true"
            :allow-deleting="false"
            :allow-adding="false"
            :use-icons="true"
        />
        <DxRemoteOperations
            :sorting="true"
            :filtering="true"
            :searching="true"
        />
        <DxColumn
            :allow-editing="false"
            data-field="userName"
            :caption="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.tableHeaders.userName').toUpperCase()"
        />
        <DxColumn
            :allow-editing="true"
            data-field="description"
            :caption="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.tableHeaders.description').toUpperCase()"
            :calculate-cell-value="calculateDescriptionCellValue"
        />
        <DxColumn
            :allow-editing="false"
            :caption="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.tableHeaders.accountType').toUpperCase()"
            :calculate-cell-value="calculateTypeCellValue"
        />
        <DxColumn
            data-field="accountStatus"
            :caption="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.tableHeaders.status').toUpperCase()"
        >
            <DxLookup
                :dataSource="stati"
                display-expr="localized"
                value-expr="status"
            />
        </DxColumn>
        <DxColumn
            data-field="validUntil"
            data-type="date"
            :caption="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.tableHeaders.validUntil').toUpperCase()"
            :visible="true"
        />
        <DxColumn
            data-field="allowDialin"
            :caption="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.tableHeaders.allowDialin').toUpperCase()"
            :visible="true"
        />
        <DxColumn
            data-field="siamEntityId"
            name="owner"
            :caption="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.tableHeaders.owner').toUpperCase()"
            :visible="showOwner"
            :allow-sorting="false"
        >
            <DxLookup
                :dataSource="owners"
                displayExpr="displayName"
                value-expr="siamEntityId"
                />
        </DxColumn>

        <DxColumn
            data-field="company"
            :caption="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.tableHeaders.userSummary').toUpperCase()"
            :visible="false"
        />
        <DxColumn
            data-field="givenName"
            :caption="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.tableHeaders.givenName').toUpperCase()"
            :visible="false"
        />
        <DxColumn
            data-field="sn"
            :caption="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.tableHeaders.sn').toUpperCase()"
            :visible="false"
        />
        <DxColumn
            type="buttons"
            :width="110"
            alignment="left"
        >
            <DxButton
                name="edit"
            />
            <DxButton
                name="editPassword"
                icon="key"
                :on-click="editPasswordClicked"
                :visible="isEditPasswordVisible"
                :hint="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.buttons.resetPassword')"
            />
            <DxButton
                name="resetPassword"
                icon="email"
                :on-click="resetPasswordClicked"
                :visible="isSendResetPasswordMailVisible"
                :hint="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.buttons.sendPasswordResetMail')"
            />
        </DxColumn>
        <DxSearchPanel
            :visible="true"
            :highlight-case-sensitive="false"
            :placeholder="this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.searchPanel.placeholder')"
        />
    </DxDataGrid>

</template>
<script>

import { Component, Prop, Vue } from 'vue-property-decorator';
import { DxColumn, DxEditing, DxDataGrid, DxLookup, DxRemoteOperations, DxSearchPanel, DxButton } from 'devextreme-vue/data-grid';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import _ from 'lodash';

@Component({
    name: 'entity',
    components: {
        DxColumn,
        DxEditing,
        DxDataGrid,
        DxLookup,
        DxRemoteOperations,
        DxSearchPanel,
        DxButton
    }
})
export default class Entity extends Vue {
    @Prop({ required: true, type: String, default: 'test' }) siamEntityId;
    @Prop({ 'required': true }) owners;
    @Prop({ 'required': true }) showOwner;
    @Prop({ 'required': true }) themeColor;

    stati = [{ 'status': 'active', 'localized': this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.tableBody.accountStatus.active').toString() },
        { 'status': 'inactive', 'localized': this.$t('uniSCustomisations.arena2036.dashboard.widgets.accountManager.tableBody.accountStatus.inactive').toString() }];

    object = [];

    editingExAccount= false;
    store = null;

    dataSource = null;

    calculateTypeCellValue (data) {
        switch (data.siamAccountType) {
        case 840: return 'PSK';
        case 801: return 'EX';
        case 820: return 'FX';
        default: return '';
        }
    }

    mounted () {
        this.store = new CustomStore({
            key: '_id',
            load: (loadOptions) => {
                const idmInstance = this.$root.getRequestService(),
                    siamEntityId = this.siamEntityId;
                let params = '';
                if (!_.isEmpty(loadOptions['filter'])) {
                    params = '&_queryFilter=(/siamEntityId eq "' + siamEntityId + '" and (' + this.$root.translateFilterExpression(loadOptions['filter']) + ')';
                } else {
                    params = '&_queryFilter=(/siamEntityId eq "' + siamEntityId + '"';
                }
                params += ')';

                if (!_.isNil(loadOptions['sort'])) {
                    let operator = "";
                    if (loadOptions['sort'][0]['desc']) {
                        operator = '-';
                    }
                    params += '&_sortKeys=' + operator + loadOptions['sort'][0]['selector'];
                } else {
                    params += '&_sortKeys=userName';
                }
                return idmInstance.get('/managed/user?_fields=userName,description,_id,siamAccountType,accountStatus,siamEntityId,validUntil,allowDialin' + params)
                    .then(({ data }) => {
                        return data.result;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
            update: (key, values) => {
                let userName = "",
                    operations = [];
                const idmInstance = this.$root.getRequestService();

                _.each(this.dataSource.items(), (value) => {
                    if (value._id === key) {
                        userName = value.userName;
                    }
                });

                _.each(values, (value, field) => {
                    operations.push({
                        'operation': 'replace',
                        field,
                        value
                    });
                });

                return idmInstance.patch('/managed/user/' + key, operations, { withCredentials: true })
                    .then(() => {
                        this.$root.displayNotification('success', this.$t('pages.access.successEdited', { 'resource': userName }).toString());
                    })
                    .catch((error) => {
                        this.$root.displayNotification('error', error.message);
                    });
            }
        });

        this.dataSource = new DataSource({
            store: this.store,
            key: '_id',
            searchExpr: ['userName'],
            searchOperation: 'co'
        });
        this.dataSource.load();
    }

    allowEditing (e) {
        /*
        *  checks if the userName of a given dataset starts with 'ex'
        *
        *  @returns: false if siamAccountType is 801 (ex-account) or siamEntity is equal to the one of the logged in user?, true if not
        *
        *  ?: this is to prevent the transfer of ownership of an own Account because only the approver account can do this.
        *
        */
        if (e.row.data.siamAccountType === 801 || e.row.data.siamEntityId === this.$root.$data.userStore.getUserState().profile.siamEntityId) {
            return false;
        } else {
            return true;
        }
    }

    onRowUpdating (e) {
        const payload = this.generateUpdatePatch(e.oldData, e.newData),
            selfServiceInstance = this.$root.getRequestService();

        selfServiceInstance.patch('/managed/user/' + e.oldData._id, payload, { withCredentials: true }).then(() => {
            this.$root.displayNotification('success', this.$t('pages.access.successEdited', { 'resource': e.oldData.userName }).toString());
        })
            .catch((error) => {
                this.$root.displayNotification('error', error.response.data.message);
            });
    }

    onSaving (e) {
        console.log(e.changes);
        console.log(this.dataSource.store());
        if (!_.isNil(e.changes[0])) {
            if (!_.isNil(e.changes[0].data.description)) {
                e.changes[0].data.description = "[Funktion/Ressource] " + _.trim(e.changes[0].data.description);
            }
        }
    }

    generateUpdatePatch (o, n) {
        let originalFrom = _.cloneDeep(o),
            newForm = _.cloneDeep(n),
            changes = [];
        _.each(newForm, (value, key) => {
            if (originalFrom[key] !== newForm[key]) {
                changes.push({
                    value: newForm[key],
                    name: key
                });
            }
        });
        return _.map(changes, (formField) => {
            if (formField.value === '' || formField.value === null) {
                return { operation: 'remove', field: '/' + formField.name };
            } else {
                return { operation: 'replace', field: '/' + formField.name, value: formField.value };
            }
        });
    }

    onEditorPreparing (e) {
        /*
        *  set colums to ReadOnly if their parentTyoe is a Datarow, dataField is siamEntityId and
        *  allowEditing returns false.
        *
        */
        if (e.parentType === 'dataRow' && e.dataField === 'siamEntityId') {
            e.editorOptions.readOnly = !this.allowEditing(e);
        }

        /* disallow editing of the description, validUntil field and allowDialin of a ex-account */
        if (e.parentType === 'dataRow' && (e.dataField === 'description' || e.dataField === 'allowDialin' || e.dataField === 'validUntil') && !(e.row.data.siamAccountType === 820 || e.row.data.siamAccountType === 840)) {
            e.editorOptions.readOnly = true;
        }
    }

    calculateDescriptionCellValue (data) {
        if (!_.isUndefined(data.description)) {
            return data.description.replace("[Funktion/Ressource]", '');
        } else {
            return "";
        }
    }

    onRowPrepared (rowElement) {
        console.log(this.siamEntityId);
        if (rowElement.rowType === 'header') {
            rowElement.rowElement.style.backgroundColor = this.themeColor.headerBackgroundColor;
            rowElement.rowElement.style.color = this.themeColor.textColor;
        }
    }

    editPasswordClicked (e) {
        this.$emit('editPasswordClicked', e.row.key);
    }

    resetPasswordClicked (e) {
        this.$emit('resetPasswordClicked', e.row.key);
    }

    isEditPasswordVisible (e) {
        if (e.row.data.siamAccountType !== 840) {
            return true;
        } else {
            return false;
        }
    }

    isSendResetPasswordMailVisible (e) {
        if (e.row.data.siamAccountType === 801) {
            return true;
        } else {
            return false;
        }
    }
}

</script>
